import bgCourse from '@/assets/images/bg-course.png';
import LazyImage from '../shared/LazyImage';
import { RightIcon } from '@/assets/icons';
import Link from 'next/link';
import { UrlLink } from '@/configs/urlLink';
import { useRouter } from 'next/router';
import { useGetLectureBySlugQuery } from '@/generated/graphql';
import { format, parseISO } from 'date-fns';

const CourseInformationCard = () => {
  const router = useRouter();
  const lectureSlug = router.asPath.replace('/khoa-hoc-kien-thuc/', '');
  const { data: detailLecture } = useGetLectureBySlugQuery({
    variables: {
      where: {
        slug: {
          _eq: lectureSlug,
        },
      },
    },
  });

  const { name, slug, image } = detailLecture?.lecture?.[0]?.lecturer || {};

  return (
    <div className="w-full flex flex-col gap-2">
      <div className="bg-secondary-blueLight md:bg-[#F8F8F8] flex flex-row md:flex-col lg:flex-row w-full p-3 gap-4 md:items-start items-center lg:items-center justify-between rounded-[6px]">
        <div className="bg-primary relative w-[48px] h-[48px] rounded-[50%]">
          <LazyImage
            src={image}
            alt="Thumbnail course"
            fill
            sizes="(max-width: 768px) 100vw, (max-width: 768px) 50vw, 25vw"
            className="object-fill"
            style={{ borderRadius: '50%' }}
          />
        </div>
        <div className="flex flex-col mr-2">
          <span className=" text-PC-Title-Regular text-neutral-grayDark font-sans">
            Giảng viên
          </span>
          <span className="text-PC-Title-Medium text-primary-easternDark font-sans">
            {name}
          </span>
        </div>
        <Link href={`${UrlLink.knowledge.author}/${slug}`}>
          <RightIcon />
        </Link>
      </div>

      <div className="bg-[#F8F8F8] flex flex-col p-3 w-full gap-4 rounded-[6px]">
        <div className="flex flex-col">
          <span className="text-PC-Title-Regular text-neutral-grayDark font-sans">
            Thời gian
          </span>
          <span className="text-PC-Title-Medium text-neutral-dark font-sans">
            {detailLecture?.lecture?.[0]?.start_date &&
              format(
                parseISO(detailLecture?.lecture?.[0]?.start_date),
                'HH:mm dd/MM/yyyy',
              )}
          </span>
        </div>

        <div className="flex flex-col">
          <span className="text-PC-Title-Regular text-neutral-grayDark font-sans">
            Hình thức
          </span>
          <span className="text-PC-Title-Medium text-neutral-dark font-sans">
            Online - Zoom
          </span>
        </div>
      </div>
    </div>
  );
};
export default CourseInformationCard;

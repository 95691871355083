import { useInsert_Contact_FormMutation } from '@/generated/graphql';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Input from '@/components/shared/AppInput';
import ThankYouNews from '../News/ThankYouNews';
import CourseSharing from './CourseSharing';

interface IContactFormProps {
  title?: string;
  description?: string;
}

export function RegisterCourse({ title, description }: IContactFormProps) {
  const router = useRouter();
  const url = router.asPath;

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [insertContactFormMutation, { loading }] =
    useInsert_Contact_FormMutation({
      onCompleted: _ => {
        setShowSuccessMessage(true);
      },
    });

  const formik = useFormik({
    initialValues: { name: '', email: '', phone: '' },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required('Vui lòng nhập địa chỉ email!')
        .email('Email không hợp lệ!'),
      phone: Yup.string()
        .required('Vui lòng nhập số điện thoại!')
        .matches(/^(?:\+84|0)(?:\d{9,10})$/, 'Số điện thoại không hợp lệ!'),
    }),
    onSubmit: async values => {
      const { email, name, phone } = values;
      await insertContactFormMutation({
        variables: {
          objects: {
            email,
            full_name: name,
            phone_number: phone,
            content: `${
              title ? 'Trang danh mục tin tức' : 'Trang chi tiết tin tức'
            } ${url}`,
          },
        },
      });

      formik.resetForm();
    },
  });

  return (
    <div className="bg-gray-variants-3">
      <form onSubmit={formik.handleSubmit}>
        <div className="bg-neutral-grayLight1 flex flex-col justify-center rounded-lg px-4 py-6">
          <p className="text-PC-Display-H5 w-full font-sans text-primary-easternDark">
            ĐĂNG KÝ NGAY!
          </p>

          <div className="flex w-full flex-col gap-2 py-2">
            <Input
              label="Họ và tên của bạn"
              value={formik.values.name}
              onChange={formik.handleChange}
              disabled={loading}
              name="name"
            />
            <Input
              label="Email của bạn"
              required
              disabled={loading}
              value={formik.values.email}
              onChange={formik.handleChange}
              name="email"
            />
            {formik.errors.email && (
              <small className="text-red-400">{formik.errors.email}</small>
            )}
            <Input
              label="Số điện thoại"
              required
              disabled={loading}
              value={formik.values.phone}
              onChange={formik.handleChange}
              name="phone"
            />
            {formik.errors.phone && (
              <small className="text-red-400">{formik.errors.phone}</small>
            )}
          </div>

          <button
            className="text-PC-Button-Medium my-2 bg-primary-easternDark hover:bg-primary-eastern h-[40px] w-full rounded-[4px] px-4 py-2 font-bold text-white"
            type="submit"
            disabled={loading}
          >
            Đăng ký tham gia
          </button>

          <div className="mt-2">
            <span className="text-PC-Title-Regular text-neutral-gray mb-2">
              Chia sẻ khoá học
            </span>
            <CourseSharing />
          </div>
        </div>
      </form>
      {showSuccessMessage && (
        <ThankYouNews setShowSuccessMessage={setShowSuccessMessage} />
      )}
    </div>
  );
}

import React from 'react';
import Link from 'next/link';
import { UrlLink } from '@/configs/urlLink';
import bgCourse from '@/assets/images/bg-course.png';
import LazyImage from '../shared/LazyImage';
import { FileIcon } from '@/assets/icons';
import { ICourseKnowledge } from '@/interfaces/Course';
import { COURSE_STATUS } from '@/constants/course';
import { useRouter } from 'next/router';
import { useGetLectureBySlugQuery } from '@/generated/graphql';

interface DetailCourseCardProps {
  data?: ICourseKnowledge;
}

export const DetailCourseCard: React.FC<DetailCourseCardProps> = ({ data }) => {
  const router = useRouter();
  const lectureSlug = router.asPath.replace('/khoa-hoc-kien-thuc/', '');
  const { data: detailLecture } = useGetLectureBySlugQuery({
    variables: {
      where: {
        slug: {
          _eq: lectureSlug,
        },
      },
    },
  });
  const { name, description, start_date, slug, image, lecture_files } =
    detailLecture?.lecture?.[0] || {};

  const courseStatusEnd = new Date(start_date) < new Date();

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-col">
        <div className="bg-primary relative h-[172px] md:h-[172px] lg:h-[363px] md:min-w-[664px]">
          <LazyImage
            src={image || bgCourse}
            alt="Thumbnail course"
            fill
            sizes="(max-width: 768px) 100vw, (max-width: 768px) 50vw, 25vw"
            className="object-fill"
            style={{ borderRadius: '4px' }}
          />
          <div
            className={`  text-PC-Caption-Regular border-radius-[2px] px-2 py-1  absolute right-[10px] top-[10px]
              ${
                courseStatusEnd
                  ? 'bg-neutral-grayLight text-primary-easternDark'
                  : 'bg-secondary-greenLight text-secondary-greenDark'
              }
            `}
          >
            {courseStatusEnd ? 'Đã kết thúc' : 'Đang diễn ra'}
          </div>
        </div>
        <div className="pt-4 flex flex-col">
          <span className="text-PC-Display-H4 text-[#06101A] font-header">
            {name || 'Tên khóa học'}
          </span>
        </div>
        <div className="pt-4 flex flex-col">
          <span className="text-PC-Title-Regular text-[#304050] font-sans">
            {description}
          </span>
        </div>

        {lecture_files && lecture_files?.length > 0 && (
          <div className="pt-4 flex flex-col">
            <span className="text-PC-Title-Regular text-[#020509] font-sans">
              Tài liệu
            </span>
            {Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                className="flex flex-row gap-2 py-2 items-center"
              >
                <FileIcon />
                <Link
                  href={''}
                  className="text-PC-Body-Regular text-primary-easternDark border-b border-primary-easternDark"
                >
                  Tài liệu phân tích kỹ thuật
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

import { HideIcon, FacebookShare, CopyShare, ZaloShare } from '@/assets/icons';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from '../shared';
import { FacebookShareButton } from 'react-share'; // Import FacebookButton
import { copyTextToClipboard } from '@/utils';
import toast from 'react-hot-toast';

interface CourseSharingProps {
  view?: string;
}

const CourseSharing = ({ view }: CourseSharingProps) => {
  const router = useRouter();
  const [isCopied, setIsCopied] = useState(false);
  const [currentDomain, setCurrentDomain] = useState('');

  const handleCopyUrl = () => {
    copyTextToClipboard(`${currentDomain}${router.asPath}`).then(() => {
      setIsCopied(true);
    });
    toast.success('Đã sao chép đường dẫn!');
  };

  useEffect(() => {
    if (window) setCurrentDomain(window.location.origin);
  }, []);

  const ref = useRef<any>();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://sp.zalo.me/plugins/sdk.js';
    script.async = true;
    ref.current.appendChild(script);
  }, []);

  return (
    <div
      ref={ref}
      className="flex flex-row justify-start items-center gap-4 mt-2"
    >
      <Tooltip text="Chia sẻ qua Zalo">
        <div
          style={{ cursor: 'pointer', zIndex: 1 }}
          className="zalo-share-button"
          data-href={`${currentDomain}${router.asPath}`}
          data-oaid="579745863508352884"
          data-layout="1"
          data-color="blue"
          data-customize="true"
        >
          <ZaloShare />
        </div>
      </Tooltip>
      <FacebookShareButton
        url={`https://tct-website.skydev.vn/tin-tuc/dai-hoc-so-la-loi-giai-cho-nhu-cau-nhan-luc-so-4639`}
      >
        <FacebookShare />
      </FacebookShareButton>
      <Tooltip
        text={isCopied ? 'Đã sao chép đường dẫn' : 'Sao chép đường dẫn'}
        // nowrap
      >
        <button onClick={handleCopyUrl}>
          <CopyShare />
        </button>
      </Tooltip>
    </div>
  );
};

export default CourseSharing;

import React, {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useRef,
} from 'react';
import { CloseButton } from '@/assets/icons';
import { DetailCourseCard } from './DetailCourseCard';
import CourseInformationCard from './CourseInformationCard';
import { RegisterCourse } from './RegisterCourse';
import { ICourseKnowledge } from '@/interfaces/Course';
import { useRouter } from 'next/router';

interface DetailAuthorModalProps {
  data?: ICourseKnowledge;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}
const DetailCourseModal: React.FC<DetailAuthorModalProps> = ({
  data,
  setShowModal,
}) => {
  const router = useRouter();
  const modalRef = useRef<HTMLDivElement>(null);
  const handleClickOutside: MouseEventHandler<HTMLDivElement> = useCallback(
    event => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setShowModal(false);
      }
      router.replace(
        { pathname: router.pathname, query: { ...router.query } },
        undefined,
        {
          scroll: false,
        },
      );
    },
    [setShowModal],
  );

  return (
    <div
      onClick={handleClickOutside}
      className="fixed inset-0 z-50 flex justify-center bg-[#00000099] backdrop-blur-sm"
    >
      <div
        ref={modalRef}
        className="mx-2 pb-4 my-[20px] flex flex-col gap-4 bg-white md:inset-x-auto md:top-[50px] h-[96vh] md:mx-0 w-full lg:w-auto"
      >
        <div className="flex flex-col">
          <div
            className="flex flex-row items-center justify-end bg-neutral-grayDark px-4 py-1 cursor-pointer"
            onClick={() => setShowModal(false)}
          >
            <CloseButton className="cursor-pointer" />
            <span className="text-PC-Title-Regular text-[#DCDEE0] font-sans">
              ĐÓNG
            </span>
          </div>
          <div className="flex justify-center py-4 pl-4 pr-2 md:p-6 h-[88vh] overflow-y-scroll">
            <div className="flex flex-col md:gap-4 lg:gap-2">
              <div className="flex flex-col justify-between gap-4 md:px-4lg:container md:flex-row lg:px-0 mt-4">
                <div className="flex flex-col md:max-w-[664px]">
                  <DetailCourseCard />
                </div>
                <div className="flex md:flex flex-col gap-2 md:max-w-[320px] my-4 md:my-0">
                  <CourseInformationCard />
                  <RegisterCourse />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DetailCourseModal;
